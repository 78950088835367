import React from 'react';
import PropTypes from 'prop-types';
import { styled, withTheme, css, up } from '@smooth-ui/core-sc';
import { UserProfileContext } from '../../containers/WebApp/WebApp';
import { Lock } from 'styled-icons/boxicons-solid/Lock';
import PrivateComponent from '_platform/src/utils/PrivateComponent';
import Button from '_platform/src/components/Button/Button';

const StyledWelcomeSimple = styled.div`
  align-items: center;
  background-color: ${props => props.theme.themeColor};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  text-align: center;
  width: 100%;
  ${up(
    'md',
    css`
      flex-direction: row;
      height: 80px;
      margin-left: auto;
      text-align: left;
      width: inherit;
      &:before {
        border-bottom: 40px solid ${props => props.theme.themeColor};
        border-left: 40px solid ${props => props.theme.customHeaderBackground};
        border-right: 40px solid ${props => props.theme.themeColor};
        border-top: 40px solid ${props => props.theme.customHeaderBackground};
        content: '';
        display: block;
        left: -80px;
        position: absolute;
        top: 0;
      }
    `
  )}

  && {
    padding-left: 0;
  }
`;

const WelcomeDetails = styled.div`
  font-size: 0.75rem;
  ${up(
    'md',
    css`
      margin-left: 2rem;
      margin-right: 3rem;
    `
  )}
`;

const WelcomeDetailsGreeting = styled.div`
  color: ${props => props.theme.secondary};
  font-size: 0.9rem;
  font-weight: bold;
`;
const WelcomeDetailsName = styled.div`
  font-weight: bold;
`;

const WelcomeButtons = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  a {
    min-width: 100px;
    text-align: center;
  }
`;

const WelcomeButtonLogout = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0.5rem 0 0.5rem 0.5rem;
`;

const IconLock = styled(Lock)`
  color: ${props => props.theme.gray600};
  height: 26px;
  width: auto;
`;

const StyledButton = styled(Button)`
  min-width: 100px;
`;

const WelcomeSimple = props => (
  <PrivateComponent>
    <UserProfileContext.Consumer>
      {user => (
        <StyledWelcomeSimple>
          {user && user.data && (
            <WelcomeDetails>
              <WelcomeDetailsGreeting>Welcome</WelcomeDetailsGreeting>
              <WelcomeDetailsName>{user.data.displayName}</WelcomeDetailsName>
              {user.data.businessName ? (
                <div>{user.data.businessName}</div>
              ) : null}
            </WelcomeDetails>
          )}

          <WelcomeButtons>
            {/* <Link to={props.theme.settingsApp.logoutPagePath}>Logout</Link> */}
            {props.onLogout ? (
              <WelcomeButtonLogout>
                <IconLock />
                <StyledButton size="sm" onClick={props.onLogout} variant="dark">
                  Logout
                </StyledButton>
              </WelcomeButtonLogout>
            ) : null}
          </WelcomeButtons>
        </StyledWelcomeSimple>
      )}
    </UserProfileContext.Consumer>
  </PrivateComponent>
);

WelcomeSimple.propTypes = {
  onLogout: PropTypes.func,
  theme: PropTypes.object.isRequired,
};

WelcomeSimple.defaultProps = {
  onLogout: undefined,
};

export default withTheme(WelcomeSimple);
